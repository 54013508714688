import 'react-toastify/dist/ReactToastify.css'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { useIsRouterTransitioning } from '@/hooks/core/useIsRouterTransitioning'
import { useAuth } from '@/hooks/use-auth'
import { usePathname } from '@/navigation'
import { env } from '@/utils/envs'
import type { Integrador } from '@/utils/types/structs/auth'

import TermoColaboracaoCliente from '../cliente/TermoColaboracaoCliente'
import ClicksignIntegrador from './ClicksignIntegrador'
import CookiesNote from './cookies-note/cookies-note'
import FullscreenLoader from './fullscreen-loader'

const DynamicGlobals: React.FC = () => {
  const isRouterTransitioning = useIsRouterTransitioning()

  const { user, context } = useAuth()
  const pathname = usePathname() || ''

  const NoSSRTermoColaboracaoParceiro = dynamic(
    () => import('../contrato/TermoColaboracaoParceiro'),
    { ssr: false }
  )
  const NoSSRAvisoTermoColaboracaoParceiro = dynamic(
    () =>
      import(
        '../contrato/TermoColaboracaoParceiro/AvisoTermocolaboracaoParceiro'
      ),
    { ssr: false }
  )

  const _p = user.perfil as Integrador
  const key =
    _p?.assinaturas?.find(
      ({ assinado, contrato }) => !assinado && !contrato?.inativo
    )?.contrato || undefined

  const { query } = useRouter()
  //This variable checks if the form was called by the site
  const fromWebsite = query?.fromWebsite

  return (
    <>
      <FullscreenLoader open={isRouterTransitioning} />
      <ToastContainer
        className="px-2"
        hideProgressBar={true}
        position="top-right"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        autoClose={45000}
        closeButton={<div className="mt-2 mr-1 text-dark-gray-500">x</div>}
      />

      <>
        {/* Apenas mostra os modais em área logada e para o ambiente BR */}
        {env.LANGUAGE === 'pt-br' ? (
          (pathname.startsWith('/area-') || pathname === '/projects') &&
          user ? (
            <div>
              <TermoColaboracaoCliente />

              {context === 'VENDEDOR' && <NoSSRAvisoTermoColaboracaoParceiro />}

              {key && key.type === 'OPTIN' && <NoSSRTermoColaboracaoParceiro />}
              {key && key.type === 'CLICKSIGN' && <ClicksignIntegrador />}
            </div>
          ) : null
        ) : (
          <>
            {key && key.type === 'OPTIN' && <NoSSRTermoColaboracaoParceiro />}
          </>
        )}

        {!fromWebsite && <CookiesNote />}
      </>
    </>
  )
}

export default DynamicGlobals
